<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
        <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
        <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

        </v-breadcrumbs>
    </div></v-row><br><br>
        <br />
        <v-card class="card">
            <v-card-title class="heading justify-center">
                <span>Publisher</span>
            </v-card-title>
            
        <div v-if="init_loading">
          <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card-text>
                <div class="add-section">
                    <div style="text-align: center;">
                        <v-col>
                            <v-form ref="form">
                                <label class="add-text">Add New Publisher</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="publisher" outlined dense :rules="[rules.required]"
                                    clearable required></v-text-field>
                                <label class="add-text">Display Order</label><span style="color: red;"> *</span>
                                <v-text-field class="text" v-model="display_order" outlined dense :rules="[rules.required]"
                                type="number" :min="0"  required></v-text-field>
                                <label class="add-text">Short Name</label>
                                <v-text-field class="text" v-model="short_name" outlined dense :rules="[rules.required]"
                                    clearable required></v-text-field>

                                <v-btn color="success" @click="addPublisher">Add</v-btn>
                            </v-form>
                        </v-col>
                    </div>
                </div>

                <template>
                    <div class="text-center">
                        <v-dialog v-model="dialog" width="500">
                            <v-card>
                                <v-card-title class="grey lighten-2">
                                    Edit Publisher
                                </v-card-title>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Publisher</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="publisherName" outlined dense
                                        :rules="[rules.required]" clearable required></v-text-field>
                                </v-card-text>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Display Order</label><span style="color: red;"> *</span>
                                    <v-text-field class="text" v-model="display_order" outlined dense
                                        :rules="[rules.required]"  type="number" :min="0"  required></v-text-field>
                                </v-card-text>

                                <v-card-text style="padding-bottom: 0px;">
                                    <label class="add-text">Short Name</label>
                                    <v-text-field class="text" v-model="short_name" outlined dense :rules="[rules.required]"
                                        clearable required></v-text-field>
                                </v-card-text>

                                <!-- <v-divider></v-divider> -->

                                <div class="text-center">
                                    <v-spacer></v-spacer>
                                    <v-btn class="m-3" @click="dialog = false">
                                        Close
                                    </v-btn>
                                    <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                </div>
                            </v-card>
                        </v-dialog>
                    </div>
                </template>

                <template v-if="publisher_list != null">
                    <v-data-table :headers="headers" :items="publisher_list" :search="search" class="elevation-1 nowrap">
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-spacer></v-spacer>
                                <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table" single-line
                                    hide-details></v-text-field>
                            </v-toolbar>
                        </template>
                        <template v-slot:item.edit="{ item }">
                            <!-- <v-icon v-if="item.edit==true" color="success" class="mr-2">mdi-check-circle-outline</v-icon> -->
                            <v-icon v-if="item" class="mr-2" @click="updatePublisher(item)">mdi mdi-pencil-box</v-icon>
                        </template>
                        <template v-slot:item.actions="{ item }">
                            <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                            <v-switch v-model="item.publisherIsactive" @change="editItem(item)"></v-switch>
                        </template>
                        <template v-slot:item.delete="{ item }">
                            <v-icon v-if="item" class="mr-2" @click="deletePublisher(item)"> mdi mdi-delete</v-icon>
                        </template>
                    </v-data-table>
                </template>
            </v-card-text>
            </div>
        </v-card>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        overlay: false,
        init_loading: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        dialog: false,
        search: "",
        publisher: null,
        publisherId: null,
        publisherName: null,
        short_name: null,
        display_order: null,
        userType: null,
        edit_btn_load: false,
        editUserType: null,
        editUserTypeId: null,
        rules: {
            required: (value) => !!value || "Required.",
        },
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Admin Master',
                disabled: false,
                href: '/admin-master',
            },
            {
                text: 'Publisher',
                disabled: true,
                href: '',
            },
        ],
        publisher_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "publisher" },
            { text: "Short Name", value: "short_name" },
            { text: "Edit", value: "edit" },
            { text: "Is-active?", value: "actions", sortable: false },
            { text: "Delete", value: "delete" },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            axios
                .post("/admin/publisherIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Publisher updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updatePublisher(item) {
            this.dialog = true;
            this.publisherName = item.publisher;
            this.display_order = item.display_order;
            this.short_name = item.short_name;
            this.publisherId = item.publisherId;
        },

        saveEdit(item) {
            if (this.publisherName) {
                this.publisherName = this.publisherName.trim()
            }
            console.log(item);
            const data = {
                publisherName: this.publisherName,
                publisherId: this.publisherId,
                publisherDisplayOrder: this.display_order,
                publisherShortName: this.short_name,
            };
            if(!this.publisherName){
                this.showSnackbar("#b71c1c", "Please Enter Publisher");
                return;
            }
            else if(!this.display_order){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }

                axios
                    .post("/admin/editPublisher", data)
                    .then((res) => {
                        if (res.data.msg == "success") {
                            this.sec_mode_list = res.data.user_list;
                            this.showSnackbar("#4CAF50", "Publisher updated successfully...");
                            this.onload();
                            this.dialog = false;
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
           
        },
        deletePublisher(item) {
            const data = {
                publisherName: item.publisher,
            };
            axios
                .post("/admin/deletePublisher", data)
                .then((res) => {
                    if (res.data.msg == "200") {
                        this.showSnackbar("#4CAF50", "Publisher Deleted Successfully  !!");
                        this.onload();
                    }
                    else if (res.data.msg == "exp") {
                        this.showSnackbar("#b71c1c", "Can not be Deleted!!!");
                    }
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    window.console.log(error);
                });
        },
        onload() {
            this.init_loading = true
            axios
                .post("/admin/getPublisher")
                .then((res) => {
                    this.org = res.data.org;
                    this.publisher_list = res.data.publisher;
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addPublisher() {
            if (this.publisher) {
                this.publisher = this.publisher.trim()
            }
            const data = {
                publisher_name: this.publisher,
                publisherDisplayOrder: this.display_order,
                publisherShortName: this.short_name,
            };

            if(!this.publisher){
                this.showSnackbar("#b71c1c", "Please Enter Publisher");
                return;
            }
            else if(!this.display_order){
                this.showSnackbar("#b71c1c", "Please Enter Display Order");
                return;
            }
            
                axios
                    .post("/admin/addPublisher", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.publisher_list = res.data.publisher_list;
                        if (res.data.msg == "success") {
                            this.showSnackbar("#4CAF50", "Publisher added successfully...");
                            this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
